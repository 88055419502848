import {action, makeAutoObservable, observable} from "mobx";
import {bigFieldState} from "./index";

class GameStore {
  activeFields = [[1, 1]];
  winnerFields = Array(3).fill(Array(3).fill(null));
  activeSign = "X";

  constructor() {
    makeAutoObservable(this, {
      activeFields: observable,
      activeSign: observable,
      winnerFields: observable,
      changeActiveFields: action,
      toggleSign: action
    })
  }

  init(bigField) {
    this.activeFields = [[1, 1]];
    this.winnerFields = Array(3).fill(Array(3).fill(null));
    this.activeSign = "X";
    bigField.init();
  }

  toggleSign() {
    this.activeSign = this.activeSign === "X" ? "O" : "X";
  }

  checkFields(arr1, arr2) {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].toString() === arr2.toString()) return true;
    }
    return false;
  }

  setActiveFields(bigTable) {
    bigTable.forEach((tables, i) => {
      tables.forEach((table, j) => {
        if (this.checkFields(this.activeFields, [i, j])) {
          bigTable[i][j].makeActive();
        }
        else {
          bigTable[i][j].disableActive();
        }
      })
    });
  }

  changeActiveFields(newActiveField) {
    this.activeFields = newActiveField;
  }

  addWinnerFields([i, j], whoWin) {
    this.winnerFields[i][j] = whoWin;
  }

  draw(talesTable) {
    return talesTable.every(row => row.every(item => item !== null));
  }

  win(talesTable) {
    let i, j;
    for (i = 0; i < 3; i++) {
      if (talesTable[i][0] === talesTable[i][1] && talesTable[i][1] === talesTable[i][2] && talesTable[i][0] !== null) {
        return talesTable[i][0];
      }
    }
    for (j = 0; j < 3; j++) {
      if (talesTable[0][j] === talesTable[1][j] && talesTable[1][j] === talesTable[2][j] && talesTable[0][j] !== null) {
        return talesTable[0][j];
      }
    }
    if (talesTable[0][0] === talesTable[1][1] && talesTable[1][1] === talesTable[2][2] && talesTable[0][0] !== null) {
      return  talesTable[0][0];
    } else if (talesTable[2][0] === talesTable[1][1] && talesTable[1][1] === talesTable[0][2] && talesTable[2][0] !== null) {
      return talesTable[2][0];
    }
    
    return false;
  }

  step(coords) {
    const [bigRow, bigCol, smallRow, smallCol] = coords;
    const talesTable = bigFieldState.talesTable[bigRow][bigCol];

    if (!this.win(talesTable.talesTable) && talesTable.talesTable[smallRow][smallCol] === null) {
      talesTable.step(smallRow, smallCol, this.activeSign);

      if (this.draw(talesTable.talesTable)) {
        talesTable.setWhoWin("XO");
        this.addWinnerFields([bigRow, bigCol], talesTable.whoWin);
      }

      if (this.win(talesTable.talesTable)) {
        talesTable.setWhoWin(this.activeSign);
        this.addWinnerFields([bigRow, bigCol], talesTable.whoWin);
      }

      this.toggleSign();

      if (this.winnerFields[smallRow][smallCol]) {
        this.makeAllActive();
      } else {
        this.changeActiveFields([[smallRow, smallCol]]);
      }

    }
  }

  /*theEnd(bigTable) {
    // console.log('kek')
    bigTable.forEach((tables, i) => {
      tables.forEach((table, j) => {
        table.talesTable.forEach(row => row.forEach(cell => console.log(cell))) //Вывод значения всех клеток
        console.log(table, bigTable[i][j]) //Поля для диактивации
        // table.talesTable.disableActive();
        // bigTable[i][j].disableActive();
      })
    });
  }*/

  makeAllActive() {
    const actives = []
    for (let i = 0; i < 3; ++i) {
      for (let j = 0; j < 3; ++j) {
        if (!this.winnerFields[i][j]) {
          actives.push([i, j]);
        }
      }
    }
    this.changeActiveFields(actives);
  }
}

export default GameStore;
