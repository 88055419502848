import {Row} from "./Row";
import {TableTales} from "./TableTales";
import {bigFieldState} from "../store";
import {observer} from "mobx-react-lite";

export const BigTableTales = observer((activeGame) => {
  return (
    <div className="table-tales table-tales--big">
        {
          bigFieldState.talesTable.map((row, i) => (
            <Row key={i}>
              {
                row.map((col, j) => (
                  <TableTales table={col} coords={[i, j]} activeGame={activeGame} key={`${i}${j}`} />
                ))
              }
            </Row>
          ))
        }
    </div>
  )
})
