import {bigFieldState, fieldState, gameStore} from "../store";
import {observer} from "mobx-react-lite";
import {BigTableTales} from "./BigTableTales";
import { useEffect, useState } from "react";


export const Game = observer(() => {
  gameStore.setActiveFields(bigFieldState.talesTable);
  const [whoWin, setWhoWin] = useState(false);
  
  useEffect(() => {
    //console.log('смена', gameStore.win(gameStore.winnerFields))
    // const whoWin = gameStore.win(gameStore.winnerFields);
    if (gameStore.win(gameStore.winnerFields)) {
      setWhoWin(gameStore.win(gameStore.winnerFields));
      console.log(`Победил ${gameStore.win(gameStore.winnerFields)}`);
    }
    if(gameStore.winnerFields.every(row => row.every(item => item !== null))){ //Немного кастыль, но всё-таки если ваще все поля заняты, значит это ничья.
      setWhoWin("XO");
      console.log('Победила дружба');
    }
    
  }, [gameStore.activeSign])
  
  return (
    <div className="game">
      {/* <p className="finalInscription">{1}</p> */}
      {whoWin && <p className="finalInscription">{whoWin === "X" && 'победа'} {whoWin === "O" && 'поражение'} {whoWin === "XO" && 'ничья'}</p>}
      <BigTableTales activeGame={whoWin === false}/>
    </div>
  )
})
