import {action, makeAutoObservable, observable} from "mobx";
import {gameStore} from "./index";

class FieldState {
  isBigField = false;
  talesTable = Array(3).fill(Array(3).fill(null));
  isActive = false;
  whoWin = null;

  constructor() {
    makeAutoObservable(this, {
      talesTable: observable,
      isActive: observable,
      isBigField: observable,
      makeActive: action,
      disableActive: action,
      setWhoWin: action,
    })
  }

  makeActive() {
    this.isActive = true;
  }

  disableActive() {
    this.isActive = false;
  }

  step(x, y, sign) {
    this.talesTable[x][y] = sign;
  }

  setWhoWin(sign) {
    this.whoWin = sign;
  }
}


export default FieldState;
