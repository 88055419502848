import FieldState from "./fieldState";
import BigFieldState from "./bigFieldState";
import GameStore from "./gameStore";


const bigFieldState = new BigFieldState();
const fieldState = new FieldState();
const gameStore = new GameStore();

export {
  bigFieldState,
  fieldState,
  gameStore
}
