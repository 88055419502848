import React from "react";
import {useState} from 'react';
import {observer} from "mobx-react-lite";

import {Game} from "./components/Game";
import {HowPlay} from "./components/HowPlay";

import './App.scss';
import HowPlayImg from "./images/pravila.svg";
import {bigFieldState, gameStore} from "./store";
import { createPortal } from "react-dom";

function App() {
  const [hide, setHide] = useState(false);
  const [howPlay, setHowPlay] = useState(false);

  const openHowPlay = () => {
    setHowPlay(true);
  }

  const closeHowPlay = () => {
    setHowPlay(false);
  }

  return (
      <div className="container">
        <div className="main">
          <div className="main__wrapper">
            <div className="main__left">
              <button className="main__button reset" onClick={() => gameStore.init(bigFieldState)}></button>
            </div>
            <Game />
            <div className="main__right">
              <button className={`main__button ${hide ? "eye-hide" : "eye"}`} onClick={() => setHide(!hide)}></button>
            </div>
          </div>
        </div>

        <button className="how-play-open" onClick={() => openHowPlay()}>
          <img src={HowPlayImg} alt="как играть?"/>
        </button>

        {howPlay && createPortal(<HowPlay/>, document.querySelector('#modal'))}
      </div>
  );
}

export default observer(App);
