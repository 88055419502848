import {Row} from "./Row";
import {Tale} from "./Tale";
import {observer} from "mobx-react-lite";
import {gameStore} from "../store";

import XImg from "../images/krest.png";
import OImg from "../images/nol.png";
import { useEffect, useState } from "react";

export const TableTales = observer(({table, coords, activeGame}) => {
  // console.log(activeGame)
  let img = null;
  const [final, setFinal] = useState(document.querySelector('.finalInscription'));

  // console.log(document.querySelector('.finalInscription'))
  // useEffect(() => {
  //   console.log(document.querySelector('.finalInscription'))
  // }, [document.querySelector('.finalInscription') !== null])

  if (table.whoWin === "X") {
    img = <img src={XImg} alt="X"/>
  } else if (table.whoWin === "O") {
    img = <img src={OImg} alt="O"/>
  }

  return (
    <div className={`table-tales table-tales--small${table.whoWin === "XO" ? " table-tales--draw" : ""}`}>
      {
        table.talesTable.map((row, i) => {
          return (
            <Row key={i}>
              {row.map((col, j) => (
                <Tale key={`${i}${j}`} active={table.isActive && activeGame.activeGame} step={() => gameStore.step([...coords, i, j])}>
                  {table.talesTable[i][j]}
                </Tale>
              ))}
            </Row>
          )})
      }
      {img}


    </div>
  )
  }
)
