import FieldState from "./fieldState";
import {makeAutoObservable, observable} from "mobx";

class BigFieldState {
  talesTable = [
    [
      new FieldState(),
      new FieldState(),
      new FieldState(),
    ],
    [
      new FieldState(),
      new FieldState(),
      new FieldState(),
    ],
    [
      new FieldState(),
      new FieldState(),
      new FieldState(),
    ],
  ]

  constructor() {
    makeAutoObservable(this, {
      talesTable: observable,
    })
  }

  init() {
    this.talesTable = [
      [
        new FieldState(),
        new FieldState(),
        new FieldState(),
      ],
      [
        new FieldState(),
        new FieldState(),
        new FieldState(),
      ],
      [
        new FieldState(),
        new FieldState(),
        new FieldState(),
      ],
    ]
  }
}

export default BigFieldState;
