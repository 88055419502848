import XImg from "../images/krestik.png";
import OImg from "../images/nolik.png";
import { gameStore } from "../store";

export const Tale = ({active, step, children}) => {
  let img = null;

  if (children === "X") {
    img = <img className="tale__img" src={XImg} alt="x"/>
  } else if (children === "O") {
    img = <img className="tale__img" src={OImg} alt="0"/>
  }
  
  return (
    <button disabled={!active} onClick={() => step()} className={`tale ${img === null ? "" : "tale--with-img"}`}>
      {img}
    </button>
  )
}
