import {useState} from "react";

import Step1 from "../images/1.png";
import Step2 from "../images/2.png";
import Step3 from "../images/3.png";
import Step4 from "../images/4.png";
import Step5 from "../images/5.png";

import pole_1 from "../images/gifs/1_pole.gif";
import perehod_1_2 from "../images/gifs/1-2.gif";
import kletki_figura_2 from "../images/gifs/2_kletki-figura.gif";
import perehod_2_3 from "../images/gifs/2-3.gif";
import win_3 from "../images/gifs/3_win.gif";
import perehod_3_4 from "../images/gifs/3-4.gif";
import hod_4_1 from "../images/gifs/4_hod-1.gif";
import hod_4_2 from "../images/gifs/4_hod-2.gif";
import hod_4_3 from "../images/gifs/4_hod-3.gif";

import Next from "../images/next.png";
import CloseHowPlay from "../images/CloseHowPlay.png";
import HowPlayBg from "../images/HowPlayBg.jpg";

const images = [Step1, Step2, Step3, Step4, Step5]
const gifs = [pole_1, perehod_1_2, kletki_figura_2, perehod_2_3, win_3, perehod_3_4, hod_4_1, hod_4_2, hod_4_3]

export const HowPlay = () => {
  const [i, srtI] = useState(0);
  const [viewImages, setImages] = useState(gifs[i]);
  const [text, setText] = useState("в игре 9 полей, 81 клетка");
  // const [viewImages, setImages] = useState([images[0]]);
  const [nextBtn, setNextBtn] = useState(false);


  const next = () => {
    if (!nextBtn) {
      setImages([...viewImages, images[viewImages.length]]);
    }

    if (viewImages.length === images.length - 1) {
      setImages([...viewImages, images[viewImages.length]]);
      setNextBtn(true);
    }
  }

  function textUpdate(ind){
    console.log(ind)
    switch(ind){
      case 1: 
        setText("")
        break;
      case 2: 
        setText("3 клетки в ряд = победа в поле")
        break;
      case 3: 
        setText("")
        break;
      case 4: 
        setText("3 поля в ряд = победа")
        break;
      case 5: 
        setText("")
        break;
      case 6: 
        setText("ход в клетку опредляет поле, где будет следующий ход")
        break;
      case 7: 
        setText("ход в правую клетку ознает, что противник пойдёт в правом поле")
        break;
      case 8: 
        setText("выбирая левую верхнюю клетку следующий ход будет в левом верхнем поле ");
        Next = '';
        break;
    }
  }

  const nextStep = (ind) => {
    // console.log(ind)
    if(ind % 2 === 0 || ind === 7){
      srtI(ind);
      setImages(gifs[ind]);
      textUpdate(ind);
    }else{
      setImages(gifs[ind]); textUpdate(ind);
      setTimeout(() => {
        srtI(ind+1);
        setImages(gifs[ind+1]);
        textUpdate(ind+1);
      }, parseInt(`${ind}000`));
    }
    // console.log(i)

  }

  const close = () => {
    setImages([images[0]]);
    setNextBtn(false);
    // closeHowPlay();
  }

  return (
    <div className="how-play">
      <img className="how-play__gifka" src={viewImages} />
      <div className="how-play__description">
        <p className="how-play__textik" >{text}</p>
        <button className={`how-play__next`} onClick={() => nextStep(i+1)}>
            {text && i<8 && <img src={Next} alt="next"/>}
        </button>
      </div>
      {/* <div className="how-play__wrapper">
        <div className="how-play__images">
          {viewImages.map((image, i) => (
            <img src={image} key={i}/>
          ))}
          <button className={`how-play__next${nextBtn ? " how-play__next--hide" : ""}`} onClick={() => next()}>
            <img src={Next} alt="next"/>
          </button>
        </div>

      </div> */}
      {/* <button className="how-play__close" onClick={() => close()}>
        <img src={CloseHowPlay} alt="close"/>
      </button> */}
  </div>
  )
}
